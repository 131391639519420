<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Select Date</label>
                      <flat-pickr
                        class="form-control"
                        value=""
                        :config="{
                          dateFormat: 'd/m/Y',
                          mode: 'range',
                        }"
                        placeholder="Select Date"
                        style="background-color: transparent"
                        @input="getdata($event)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Select Employee</label>
                      <v-select
                        v-model="employee"
                        placeholder="None"
                        :options="employeeOption"
                        label="name"
                      >
                        <template
                          #option="{ name, profile_image, username, surname, position }"
                        >
                          <b-avatar
                            :src="getprofileImage(profile_image)"
                            v-if="name !== 'All'"
                          />
                          <span class="font-weight-bolder">
                            {{ name }} {{ surname }}
                            {{
                              name !== "All" ? "/" + (position ? position.name : "") : ""
                            }}</span
                          >
                          <span v-if="name !== 'All'" class="font-weight-bolder"
                            >/{{ username }}</span
                          >
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Type</label>
                      <v-select
                        v-model="type"
                        placeholder="None"
                        :options="['Daily', 'Hourly']"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="4">
                    <b-form-group>
                      <label>Select Date</label>
                      <flat-pickr
                        class="form-control"
                        value=""
                        placeholder="Select Date"
                        :config="{
                          dateFormat: 'd/m/Y',
                          mode: 'range',
                        }"
                        style="background-color: transparent"
                        @input="getdata($event)"
                      />
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="primary"
                    class="ml-2"
                    @click="searchData"
                    :disabled="loading"
                  >
                    <feather-icon icon="SearchIcon" class="mr-50" />
                    <span class="align-middle">Search</span>
                  </b-button>
                </div>
              </b-card-code>
              <report-table
                :data="type == 'Daily' ? daily : hourly"
                :columns="type == 'Daily' ? fieldsDaily : fieldsHourly"
                title="Daily Working Reprot"
              >
                <template #printHeader>
                  <div style="display: flex; justify-content: center; width: 100%">
                    <h3 style="margin: 0px">Daily Working Report</h3>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      width: 100%;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <h4 style="margin: 0px">
                      Employee Name :
                      {{ employee && employee.fullname ? employee.fullname : "All" }}
                    </h4>
                    <h5 style="margin: 0px" v-if="result && result.length == 2">
                      {{ result[0] }} to {{ result[1] }}
                    </h5>
                  </div>
                </template>
              </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import moment from "moment";
import { BFormGroup, BForm, BRow, BCol, BButton, BAvatar } from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable,
    BAvatar,
  },
  data() {
    return {
      data: "",
      userOptions: [],
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      typeOptions: [
        { type: "All", name: "All" },
        { type: "channelpartner", name: "Channel Partner" },
        { type: "employee", name: "Employee" },
        { type: "franchise", name: "Franchise" },
      ],
      user: "",
      type: "Hourly",
      employee: { name: "All", value: "All" },
      clientname: "",
      vouchertype: "",
      startdate: "",
      enddate: "",
      result: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      data1: [],
      fieldsHourly: [
        {
          field: "date",
          label: "date",
        },
        {
          field: "user",
          label: "Employee",
        },
        {
          field: "from",
          label: "From",
        },
        {
          field: "to",
          label: "To",
        },
        {
          field: "hours",
          label: "Hours",
        },
        {
          field: "work",
          label: "work",
        },
        {
          field: "description",
          label: "Description",
        },
      ],
      fieldsDaily: [
        {
          field: "id",
          label: "S.R. No",
          hidden: true,
        },
        {
          field: "date",
          label: "date",
        },
        {
          field: "user",
          label: "Employee",
        },
        {
          field: "total_hours",
          label: "Total Hours",
        },
        {
          field: "remarks",
          label: "Remarks",
        },
      ],
      employeeOption: [],
      employeeIds: [],
      startdate: "",
      enddate: "",
      userData: {},
      daily: [],
      hourly: [],
      loading: false,
    };
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.employee = this.userData;
    if (this.userData.role == "admin") {
      this.getAllEmp();
    } else {
      this.getEmp();
    }
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async getAllEmp() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employeeIds = [];
          this.employeeOption = [{ name: "All", id: "All" }, ...response.data.data];
          response.data.data.map((item) => {
            this.employeeIds.push(item.id);
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    handleChild(item) {
      item.map((child) => {
        this.employeeOption.push(child);
        this.employeeIds.push(item.id);

        if (child.children.length > 0) {
          this.handleChild(child.children);
        }
      });
    },
    async getEmp() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${this.userData.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employeeOption = [{ name: "All", id: "All" }, this.userData];
          this.employeeIds = [this.userData.id];
          response.data.data.map((item) => {
            this.employeeIds.push(item.id);
            this.employeeOption.push(item);
            if (item.children.length > 0) {
              this.handleChild(item.children);
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async searchData(tableData) {
      let data = {
        startdate: this.startdate,
        enddate: this.enddate,
        employeeIds:
          this.employee && this.employee.id && this.employee.id !== "All"
            ? [this.employee.id]
            : this.employeeIds,
      };
      this.loading = true;
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/dailyworkingreport`,
        data: data,
      }).then((response) => {
        this.loading = false;
        this.daily = [];
        this.hourly = [];
        response.data.data.map((item) => {
          item.user = item.employee ? item.employee.name : "";
          item.date = moment(item.date).format("DD/MM/yyyy");
          this.daily.push(item);
          item.sub_data.map((task) => {
            task.from = moment(task.from, "HH:mm").format("hh:mm A");
            task.to = moment(task.to, "HH:mm").format("hh:mm A");
            (task.user = item.user), (task.date = item.date);
            this.hourly.push(task);
          });
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
