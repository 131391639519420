var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted font-13 mb-0"}),_c('div',[_c('b-card-code',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Select Date")]),_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"value":"","config":{
                        dateFormat: 'd/m/Y',
                        mode: 'range',
                      },"placeholder":"Select Date"},on:{"input":function($event){return _vm.getdata($event)}}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Select Employee")]),_c('v-select',{attrs:{"placeholder":"None","options":_vm.employeeOption,"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                      var name = ref.name;
                      var profile_image = ref.profile_image;
                      var username = ref.username;
                      var surname = ref.surname;
                      var position = ref.position;
return [(name !== 'All')?_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}):_vm._e(),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(name)+" "+_vm._s(surname)+" "+_vm._s(name !== "All" ? "/" + (position ? position.name : "") : ""))]),(name !== 'All')?_c('span',{staticClass:"font-weight-bolder"},[_vm._v("/"+_vm._s(username))]):_vm._e()]}}]),model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Type")]),_c('v-select',{attrs:{"placeholder":"None","options":['Daily', 'Hourly']},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.searchData}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SearchIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Search")])],1)],1)],1),_c('report-table',{attrs:{"data":_vm.type == 'Daily' ? _vm.daily : _vm.hourly,"columns":_vm.type == 'Daily' ? _vm.fieldsDaily : _vm.fieldsHourly,"title":"Daily Working Reprot"},scopedSlots:_vm._u([{key:"printHeader",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%"}},[_c('h3',{staticStyle:{"margin":"0px"}},[_vm._v("Daily Working Report")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%","flex-direction":"column","align-items":"center"}},[_c('h4',{staticStyle:{"margin":"0px"}},[_vm._v(" Employee Name : "+_vm._s(_vm.employee && _vm.employee.fullname ? _vm.employee.fullname : "All")+" ")]),(_vm.result && _vm.result.length == 2)?_c('h5',{staticStyle:{"margin":"0px"}},[_vm._v(" "+_vm._s(_vm.result[0])+" to "+_vm._s(_vm.result[1])+" ")]):_vm._e()])]},proxy:true}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }